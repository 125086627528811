.variant-container {
  .img-container {
    position: relative;
    z-index: 0;
    max-width: 549px;
    box-shadow: 0px 8px 24px -4px rgba(36, 40, 46, 0.08);
    .side-panel-thumb {
      position: absolute;
      transition: transform 200ms ease-in-out;
      z-index: 2;
      left: 0;
      filter: drop-shadow(0 0 13px transparent);
    }
  }
  &:hover {
    background: $blue-100;
    transition: 0.2s;
    .img-container {
      + {
        h5 {
          color: $primary !important;
        }
      }
    }
    .side-panel-thumb {
      transform: scale(1.05);
      filter: drop-shadow(0 0 13px rgba(0, 0, 0, 0.12));
    }
    .layout-thumb {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba($blue-100, 0.6);
      }
    }
  }
}

.showcase-gallery {
  img {
    position: relative;
    filter: drop-shadow(0px 0px 75.44px rgba(0, 0, 0, 0.12));
    border-radius: 0.5rem;
  }
  .layer-1 {
    transform: translateY(150px);
  }
  .layer-2 {
    transform: translateY(180px);
  }
  .layer-3 {
    transform: translateY(210px);
  }
  .layer-4 {
    transform: translateY(240px);
  }
  .layer-5 {
    transform: translateY(270px);
  }
  .layer-6 {
    transform: translateY(300px);
  }
}

.page-container {
  .img-container {
    position: relative;
    // z-index: 666;
    max-width: 549px;
    box-shadow: 0px 8px 24px -4px rgba(36, 40, 46, 0.08);
    .page-thumb {
      transition: transform 200ms ease-in-out;
      z-index: 2;
      left: 0;
    }
    &:hover {
      .page-thumb {
        transform: scale(1.05);
        background: rgba($blue-100, 0.6);
        filter: drop-shadow(0 0 13px transparent);
      }
    }
  }
  &:hover {
    .page-thumb {
      filter: drop-shadow(0 0 13px rgba(0, 0, 0, 0.12));
      transform: scale(1.05);
    }
    background: $blue-100;
    transition: 0.2s;
    .img-container {
      + {
        h5 {
          color: $primary !important;
        }
      }
    }
  }
}
