.content-offcanvas {
  position: fixed;
  width: 35rem !important;
  top: calc($navbar-top-height + 1px) !important;
  height: calc(100vh - ($navbar-top-height + $footer-height)) !important;
  @include media-breakpoint-up(sm) {
    background-position: 100% !important;
  }
}

.offcanvas-backdrop-transparent {
  ~ {
    .offcanvas-backdrop {
      opacity: 0;
    }
  }
}

.phoenix-offcanvas {
  position: fixed;
  overflow: auto;
  left: 0;
  bottom: 0;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
  z-index: 10;
  &.show {
    transform: none;
    + {
      .phoenix-offcanvas-backdrop {
        position: fixed;
        top: 92px;
        left: 0;
        height: 100vh;
        width: 100vw;
        background: rgba($black, $alpha: 0.1);
        z-index: 8;
      }
    }
  }
}
