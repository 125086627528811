/*-----------------------------------------------
|   Search box
-----------------------------------------------*/
.search-box {
  position: relative;
  font-size: map-get($font-sizes, '-1');
  // min-width: 15rem;
  width: 20rem;
  // @include media-breakpoint-up(lg) {
  //   width: 20rem;
  // }

  .search-box-icon {
    position: absolute;
    color: var(--#{$prefix}gray-500);
    top: 50%;
    left: 1rem;
    transform: translateY(-48%);
  }
  .search-input {
    padding-left: 2.5rem;
    padding-right: 2rem;
    border-radius: $border-radius;
    box-shadow: none;

    &::-webkit-search-cancel-button {
      display: none;
    }
  }

  [data-bs-toggle='search']:not(.show) {
    + [data-bs-dismiss='search'] {
      display: none;
    }
  }

  .file-thumbnail {
    width: 1.75rem;
    height: 1.75rem;
  }
  &.ecommerce-search-box {
    width: 31.25rem;
    .search-input {
      border-radius: 3rem;
    }
  }
}

// .safari {
//   .search-box .search-input {
//     line-height: 2;
//   }
// }
