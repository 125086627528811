.white-space-nowrap {
  white-space: nowrap;
}

// .table > thead > tr > th {
//   padding-top: 0.625rem !important;
//   padding-bottom: 0.625rem !important;
//   line-height: 1;
//   color: var(--#{$prefix}gray-700);
//   &:first-child {
//     padding-left: 0;
//   }
//   &:last-child {
//     padding-right: 0;
//   }
// }
.table {
  > thead {
    > tr {
      > th {
        padding-top: 0.625rem !important;
        padding-bottom: 0.625rem !important;
        line-height: 1;
        color: var(--#{$prefix}gray-700);
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
  > tbody {
    > tr {
      > td {
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
}
