.timeline-vertical {
  .timeline-item {
    &:not(:last-child) {
      .timeline-item-bar {
        .timeline-bar {
          position: absolute;
          height: 100px;
          left: 11px;
          top: 24px;
        }
      }
    }
  }
  &.timeline-with-details {
    .timeline-item {
      &:not(:last-child) {
        .timeline-item-bar {
          .timeline-bar {
            height: calc(100% - 1rem) !important;
          }
        }
      }
    }
  }
}
