.vertical-nav {
  .nav-link {
    position: relative;
    color: var(--#{$prefix}black);
    // padding: 0.875rem 0.5rem;
    flex: 1;
    font-weight: $font-weight-semi-bold;
    .nav-icons {
      stroke-width: 1.7px;
      color: var(--#{$prefix}black);
    }
    &.active {
      border-right-width: 0 !important;
      font-weight: $font-weight-bold;
      @include media-breakpoint-up(lg) {
        border-right-width: 1px !important;
        &::after {
          content: '';
          position: absolute;
          width: 0.625rem;
          height: 0.625rem;
          top: 13px;
          right: -6px;
          transform: rotate(-45deg);
          background: var(--#{$prefix}body-bg);
          border-width: 1px 0 0 1px;
          border-style: solid;
          border-color: var(--#{$prefix}gray-200);
        }
      }
      .nav-icons {
        stroke-width: 2.5px;
        color: var(--#{$prefix}primary);
      }
      font-weight: $font-weight-bold;
      font-size: 12.8px;
      color: var(--#{$prefix}primary);
    }
  }
}

.email-sidebar {
  top: 64px;
  // height: calc(100vh - 5rem);
  padding: map-get($spacers, 5);
  .nav-link {
    color: var(--#{$prefix}gray-1100);
    .nav-item-count {
      margin-bottom: 0;
      margin-top: map-get($spacers, 1);
      font-size: map-get($font-sizes, '-3');
      font-weight: $font-weight-bold;
      color: var(--#{$prefix}gray-700);
    }
    &.active {
      .nav-item-count {
        color: var(--#{$prefix}primary);
      }
    }
  }
  @include media-breakpoint-up(lg) {
    position: sticky;
    top: 9.5rem;
    padding: 0;
    transform: none;
    height: auto;
  }
}
.email-sidebar-width {
  width: 300px;
  @include media-breakpoint-up(lg) {
    width: 220px;
  }
}

.email-content {
  height: calc(100vh - 278px);
  overflow-x: hidden;
  .email-detail-content {
    height: calc(100% - 74px);
  }
}
.email-sidebar {
  .email-content {
    height: 100%;
    @include media-breakpoint-up(lg) {
      height: calc(100vh - 278px);
    }
  }
}

.email-textarea {
  + {
    .tox {
      min-height: 250px;
      .tox-editor-header {
        margin: 0 !important;
        padding: 0 !important;
      }
    }
  }
}

.btn-bottom-fixed {
  position: fixed;
  bottom: 120px;
  right: 24px;
}
