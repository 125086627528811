.dark {
  @each $color, $value in $dark-grays {
    --#{$prefix}gray-#{$color}: #{$value};
  }

  @each $color, $value in $dark-grays-rgb {
    --#{$prefix}gray-#{$color}-rgb: #{$value};
  }

  @each $color, $value in $dark-theme-all-colors {
    --#{$prefix}#{$color}: #{$value};
  }

  @each $color, $value in $dark-theme-colors-rgb {
    --#{$prefix}#{$color}-rgb: #{$value};
  }

  --#{$prefix}white: #{map-get($grays, '1100')};
  --#{$prefix}black: #{map-get($grays, '100')};
  --#{$prefix}white-rgb: #{to-rgb($gray-1100)};
  --#{$prefix}black-rgb: #{to-rgb($gray-100)};

  --#{$prefix}body-color: #{map-get($dark-grays, '900')};
  --#{$prefix}body-bg: #{map-get($dark-grays, 'soft')};

  /* ---------------------------------- Card ---------------------------------- */
  .card {
    --#{$prefix}card-bg: var(--#{$prefix}white);
  }

  /* --------------------------------- table --------------------------------- */
  .table {
    --#{$prefix}table-border-color: #{map-get($dark-grays, '200')};
  }

  /* ---------------------------------- input --------------------------------- */

  --#{$prefix}input-bg: #{map-get($grays, '1100')};
  --#{$prefix}input-border-color: #{map-get($dark-grays, '300')};
  --#{$prefix}input-group-addon-bg: #{$gray-900};

  /* ------------------------------- navbar-vertical ------------------------------- */
  --#{$prefix}navbar-vertical-border-color: #{map-get($dark-grays, '200')};
  --#{$prefix}navbar-footer-border-color: #{map-get($dark-grays, '200')};

  /* ------------------------------- navbar-top ------------------------------- */
  --#{$prefix}navbar-top-bg-color: #{$gray-1100};

  /* ---------------------------------- links --------------------------------- */
  --#{$prefix}link-color: #{map-get($dark-theme-colors, 'primary')};
  --#{$prefix}link-hover-color: #{map-get($theme-colors, 'primary')};

  /* ------------------------------ Badge ----------------------------- */
  .badge {
    --#{$prefix}badge-color: var(--#{$prefix}white);
  }

  /* ------------------------------ phoenix badge ----------------------------- */
  @each $color, $value in map-remove($theme-colors-300, 'secondary') {
    .badge-phoenix-#{$color} {
      --#{$prefix}badge-bg: rgba(var(--#{$prefix}#{$color}-rgb), 0.08);
      --#{$prefix}badge-color: #{$value};
      --#{$prefix}badge-border-color: rgba(var(--#{$prefix}#{$color}-rgb), 0.2);
      &[href] {
        @include hover-focus {
          color: map-get($theme-colors-dark, $color);
        }
      }
    }
  }
  .badge-phoenix-secondary {
    --#{$prefix}badge-bg: rgba(var(--phoenix-200-rgb), 0.08);
    --#{$prefix}badge-color: #{$gray-300};
    --#{$prefix}badge-border-color: rgba(var(--phoenix-1000-rgb), 0.2);
  }

  /* --------------------------------- buttons -------------------------------- */
  .btn-reveal {
    --#{$prefix}btn-color: #{map-get($dark-grays, '900')};
  }

  .btn.active {
    --#{$prefix}btn-active-bg: #{map-get($dark-grays, '300')};
    --#{$prefix}btn-active-color: red;
  }

  /* ----------------------------- phoenix-buttons ---------------------------- */

  @each $color, $value in $button-colors {
    $hover-color: map-get($theme-colors-300, $color);
    .btn-phoenix-#{$color} {
      @if $color == 'secondary' {
        --#{$prefix}btn-color: #{map-get($dark-grays, '900')};
        --#{$prefix}btn-active-color: #{map-get($dark-grays, '900')};
      } @else {
        --#{$prefix}btn-color: #{map-get($dark-theme-colors, $color)};
      }
      --#{$prefix}btn-hover-color: #{$hover-color};
      --#{$prefix}btn-bg: #{$dark-gray-soft};
      --#{$prefix}btn-border-color: #{map-get($dark-grays, '200')};
      --#{$prefix}btn-hover-bg: #{map-get($dark-grays, '100')};
      --#{$prefix}btn-active-bg: #{map-get($dark-grays, '100')};
      --#{$prefix}btn-hover-border-color: #{map-get($dark-grays, '200')};
    }
  }
  --#{$prefix}btn-active-bg: $primary;
  .settings-panel .btn {
    --#{$prefix}btn-color: #{map-get($dark-grays, '600')};
  }

  //soft buttons
  @each $color, $value in $button-colors {
    $text-color: map-get($dark-theme-colors, $color);
    $background: rgba(map-get($theme-colors, $color), 0.14);
    $hover-background: rgba(map-get($theme-colors, $color), 0.2);
    @if $color == 'secondary' {
      $text-color: map-get($dark-grays, '1000');
      $background: rgba(map-get($dark-grays, '200'), 0.5);
      $hover-background: rgba(map-get($dark-grays, '200'), 0.8);
    }
    .btn-soft-#{$color} {
      @include button-variant(
        $background: $background,
        $border: $background,
        $color: $text-color,
        $hover-background: $hover-background,
        $hover-color: $text-color
      );
    }
  }

  /* ----------------------------- outline buttons ---------------------------- */

  @each $color, $value in $button-colors {
    $hover-color: map-get($theme-hover-colors, $color);
    .btn-outline-#{$color} {
      --#{$prefix}btn-color: #{map-get($theme-colors-300, $color)};
      --#{$prefix}btn-border-color: #{map-get($theme-colors-300, $color)};
      // --#{$prefix}btn-hover-bg: #{map-get($theme-colors-300, $color)};
    }
  }

  /* --------------------------------- list js -------------------------------- */

  --#{$prefix}list-sort-icon: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgNyA5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMS4xMjkzIDQuOTkwMjNDMC42OTEwMTYgNC45OTAyMyAwLjQ3MTg3NSA1LjUwODIgMC43OTA2MjUgNS44MDcwM0wzLjE2MTMzIDguMTc3NzNDMy4zNDA2MiA4LjM3Njk1IDMuNjM5NDUgOC4zNzY5NSAzLjgzODY3IDguMTc3NzNMNi4yMDkzNyA1LjgwNzAzQzYuNTA4MiA1LjUwODIgNi4yODkwNiA0Ljk5MDIzIDUuODcwNyA0Ljk5MDIzSDEuMTI5M1pNNi4yMDkzNyAyLjg5ODQ0TDMuODM4NjcgMC41Mjc3MzVDMy42Mzk0NSAwLjM0ODQzOCAzLjM0MDYyIDAuMzQ4NDM4IDMuMTYxMzMgMC41Mjc3MzVMMC43OTA2MjUgMi44OTg0NEMwLjQ3MTg3NSAzLjIxNzE5IDAuNjkxMDE2IDMuNzE1MjMgMS4xMjkzIDMuNzE1MjNINS44NzA3QzYuMjg5MDYgMy43MTUyMyA2LjUwODIgMy4yMTcxOSA2LjIwOTM3IDIuODk4NDRaIiBmaWxsPSIjM0U0NjVCIi8+Cjwvc3ZnPgo=');
  /* --------------------------------- tinymce -------------------------------- */
  --#{$prefix}tinymce-tox-tbtn-enabled-icon-color: var(--#{$prefix}gray-1000);

  /* -------------------------------- choices -------------------------------- */
  --#{$prefix}choices-item-bg: #{rgba($blue-300, 0.25)};
  --#{$prefix}choices-item-color: var(--#{$prefix}gray-1000);
  --#{$prefix}choices-item-has-no-choices-bg: #{map-get($dark-common, 'white')};

  /* -------------------------------- dropdown -------------------------------- */
  .dropdown-menu {
    --#{$prefix}dropdown-bg: #{map-get($dark-common, 'white')};
    --#{$prefix}dropdown-border-color: #{map-get($dark-grays, '300')};
    --#{$prefix}dropdown-divider-bg: #{map-get($dark-grays, '300')};
    --#{$prefix}dropdown-link-hover-bg: #{map-get($dark-grays, '100')};
    --#{$prefix}dropdown-link-color: #{map-get($dark-grays, '1000')};
    --#{$prefix}dropdown-link-hover-color: #{map-get($dark-common, 'black')};
  }

  /* ---------------------------------- modal --------------------------------- */
  .modal-content {
    --#{$prefix}modal-bg: #{map-get($dark-common, 'white')};
  }
  /* -------------------------------- accordion ------------------------------- */
  .accordion {
    --#{$prefix}accordion-color: #{map-get($dark-grays, '1000')};
  }
  .accordion-button {
    --#{$prefix}accordion-active-color: #{map-get($dark-grays, '1100')};
  }

  /* ---------------------------------- docs ---------------------------------- */
  --#{$prefix}docs-language-bg: #{map-get($dark-grays, '100')};

  /* ------------------------------- form-select ------------------------------ */
  --#{$prefix}form-select-indicator: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUwIiBoZWlnaHQ9IjE1MCIgdmlld0JveD0iMCAwIDE1MCAxNTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik03NS4zNDggMTI3LjE5MkM3Mi40MzgxIDEyNy4xOTIgNjkuODUxNCAxMjYuMjIyIDY3LjkxMTUgMTI0LjI4Mkw1LjgzMjE1IDYyLjIwMjNDMS42Mjg4NyA1OC4zMjIzIDEuNjI4ODcgNTEuNTMyNCA1LjgzMjE1IDQ3LjY1MjVDOS43MTIxMSA0My40NDkyIDE2LjUwMiA0My40NDkyIDIwLjM4MiA0Ny42NTI1TDc1LjM0OCAxMDIuMjk1TDEyOS45OTEgNDcuNjUyNUMxMzMuODcxIDQzLjQ0OTIgMTQwLjY2MSA0My40NDkyIDE0NC41NDEgNDcuNjUyNUMxNDguNzQ0IDUxLjUzMjQgMTQ4Ljc0NCA1OC4zMjIzIDE0NC41NDEgNjIuMjAyM0w4Mi40NjEzIDEyNC4yODJDODAuNTIxMyAxMjYuMjIyIDc3LjkzNDcgMTI3LjE5MiA3NS4zNDggMTI3LjE5MloiIGZpbGw9IiM5RkE2QkMiLz4KPC9zdmc+Cg==');

  /* --------------------------------- popover -------------------------------- */
  .popover {
    --#{$prefix}popover-bg: #{map-get($dark-common, 'white')};
    --#{$prefix}popover-border-color: #{map-get($dark-grays, '300')};
    --#{$prefix}popover-body-color: #{map-get($dark-grays, '1000')};
    --#{$prefix}popover-header-bg: #{map-get($dark-grays, 'soft')};
  }

  /* ---------------------------------- toast --------------------------------- */
  .toast {
    --#{$prefix}toast-bg: rgba(var(--#{$prefix}black-rgb), 0.1);
    --#{$prefix}toast-header-color: #{map-get($dark-grays, '800')};
    --#{$prefix}toast-header-border-color: #{map-get($dark-grays, '300')};
  }

  /* --------------------------------- wizard --------------------------------- */

  --#{$prefix}theme-wizard-nav-item-circle-bg: #{map-get($dark-grays, '100')};

  /* ---------------------------------- logo ---------------------------------- */
  --#{$prefix}logo-text: #{$gray-100};

  /* ------------------------------- date-picker ------------------------------ */
  --#{$prefix}flatpickr-calendar-input-bg: #{$input-bg};
  --#{$prefix}flatpickr-calendar-bg: #{map-get($dark-common, 'white')};
  --#{$prefix}flatpickr-current-month-color: #{map-get($dark-grays, '1000')};
  --#{$prefix}flatpickr-weekday-color: #{map-get($dark-grays, '1000')};
  --#{$prefix}flatpickr-calendar-day-hover-bg: #{$gray-1000};
  --#{$prefix}flatpickr-weekend-days-color: #{$warning};
  --#{$prefix}flatpickr-today-color: #{$gray-900};
  --#{$prefix}flatpickr-calendar-day-selected-bg: #{rgba($primary, 0.2)};

  /* -------------------------------- offcanvas ------------------------------- */

  .offcanvas {
    --#{$prefix}offcanvas-bg: #{map-get($dark-common, 'white')};
  }

  /* ------------------------------- list-group ------------------------------- */
  --#{$prefix}list-group-bg: #{map-get($dark-common, 'white')};

  /* -------------------------------- progress -------------------------------- */
  .progress {
    --#{$prefix}progress-bg: #{map-get($dark-grays, '300')};
  }

  /* ------------------------------ dark classes ------------------------------ */
  .dark__border-primary {
    --phoenix-border-color: #{map-get($theme-colors, 'primary')} !important;
  }

  /* --------------------------------- global scss --------------------------------- */
  /* ------------------------------- navbar-top  ------------------------------- */
  --#{$prefix}navbar-top-link-color: #{$gray-400}; 
  --#{$prefix}navbar-top-menu-bg-color: #{$gray-1100};
  --#{$prefix}navbar-top-border-color: #{$gray-900}; 
  --#{$prefix}navbar-top-link-hover-color: #{$gray-300};

  /* ------------------------------- navbar-vertical inverted ------------------------------- */

  --#{$prefix}navbar-vertical-bg-color: #{$gray-1100};
  --#{$prefix}navbar-vertical-label-color: #{$gray-600};
  --#{$prefix}navbar-vertical-link-color: #{$gray-400};
  --#{$prefix}navbar-vertical-link-hover-color: #{$gray-100};
  --#{$prefix}navbar-vertical-link-hover-bg: #{$gray-900};
  --#{$prefix}navbar-vertical-link-active-color: #{$white};
  --#{$prefix}navbar-vertical-link-disable-color: #{$gray-500};
  --#{$prefix}navbar-vertical-link-icon-color: #{$gray-400}; //

  --#{$prefix}hamburger-menu-color: #{$gray-700};
  // ----------- collapsed colors ----------------
  --#{$prefix}navbar-vertical-hr-color: #{map-get($dark-grays, '400')};
  --#{$prefix}navbar-vertical-collapsed-menu-bg: #{$gray-1000};
  --#{$prefix}navbar-vertical-collapsed-menu-color: #{$gray-400};

  .navbar-darker {
    --#{$prefix}navbar-vertical-bg-color: #{$gray-1000};
    --#{$prefix}navbar-vertical-label-color: #{$gray-600};
    --#{$prefix}navbar-vertical-link-color: #{$gray-400};
    --#{$prefix}navbar-vertical-link-hover-color: #{$gray-100};
    --#{$prefix}navbar-vertical-link-hover-bg: #{$gray-900};
    --#{$prefix}navbar-vertical-link-active-color: #{$white};
    --#{$prefix}navbar-vertical-link-disable-color: #{$gray-500};
    --#{$prefix}navbar-vertical-link-icon-color: #{$gray-400};
    // ----------- collapsed colors ----------------
    // --#{$prefix}navbar-vertical-hr-color: #{map-get($dark-grays, '400')};
    --#{$prefix}navbar-vertical-collapsed-menu-bg: #{$gray-1000};
    --#{$prefix}navbar-vertical-collapsed-menu-color: #{$gray-400};

    //--------------- navbar top ---------------------------------
    --#{$prefix}navbar-top-bg-color: #{$gray-1000};
    --#{$prefix}nav-link-color: #{$gray-500};
    //input
    --#{$prefix}input-bg: #{$gray-1000};
    --#{$prefix}input-border-color: #{map-get($dark-grays, '300')};
    .form-control {
      color: #{$gray-600};
    }
    .search-box .search-box-icon {
      color: #{map-get($dark-grays, '500')};
    }
  }

  /* -------------------------- theme-control-toggle -------------------------- */
  --#{$prefix}theme-control-toggle-bg: #{rgba($primary, 0.24)};
  --#{$prefix}theme-control-toggle-hover-bg: #{rgba($primary, 0.7)};
  --#{$prefix}theme-control-toggle-color: #{$blue-300};
  --#{$prefix}theme-control-toggle-hover-color: #{map-get(
      $theme-colors-soft,
      'primary'
    )};
}
